import * as React from "react"
import {graphql} from "gatsby"
import PageCover from "../components/common/page-cover/page-cover";
import Layout from "../components/layout/layout";
import * as styles from "./404.module.scss"
import { Helmet } from 'react-helmet';

const NotFoundPage = ({data}) => {
  return (
      <Layout data={data.datoCmsFooter} stickyButtonsData={data.datoCmsWebStickyButton} chatbotData={data.datoCmsChatbot} metaDescription={"Not found page"} pageTemplate="other">
         <Helmet>
            <meta name="robots" content="noindex" />
        </Helmet>
        <PageCover
            title={data.datoCmsWebPageNotFound.cover[0] && data.datoCmsWebPageNotFound.cover[0].title}
            image={data.datoCmsWebPageNotFound.cover[0] && data.datoCmsWebPageNotFound.cover[0].backgroundImage}
        />
        <section className={styles.pageContainer}>
          <div className={styles.pageContent}>
              <h1 className={styles.notFoundMessage}>{data.datoCmsWebPageNotFound.notFoundMessage}</h1>
              <a href={'/'} className={styles.notFoundButton}>{data.datoCmsWebPageNotFound.goHomeButtonTitle}</a>
          </div>
        </section>
      </Layout>
  )
}

export const fragment = graphql`
    fragment PageNotFoundWebFields on DatoCmsWebPageNotFound {
        cover {
            title
            backgroundImage {
                alt
                title
                format
                url
                gatsbyImageData(layout: FIXED)
            }
        }
        goHomeButtonTitle
        notFoundMessage
    }
`

export const pageQuery = graphql`
    query PageNotFound($locale: String!) {  
        datoCmsFooter(locale: {  eq: $locale }) {
            ...FooterFields
        }   
        datoCmsWebPageNotFound(locale: {  eq: $locale }) {
            ...PageNotFoundWebFields
        }
        datoCmsWebStickyButton(locale: {  eq: $locale }) {
            ...StickyButtonsFields
        }
        datoCmsChatbot(locale: { eq: $locale }) {
            ...ChatbotFields
        }
    }
`

export default NotFoundPage
