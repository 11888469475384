// extracted by mini-css-extract-plugin
export var container = "page-cover-module--container--2490L";
export var imageContainer = "page-cover-module--imageContainer--zT2wR";
export var coverImage = "page-cover-module--coverImage--vkyGq";
export var logoContent = "page-cover-module--logoContent--y15UO";
export var titleContainer = "page-cover-module--titleContainer--rG32h";
export var backgroundImage = "page-cover-module--backgroundImage--m7qwR";
export var titleContent = "page-cover-module--titleContent--eTGJz";
export var title = "page-cover-module--title--vnpXs";
export var subtitle = "page-cover-module--subtitle--UVUs5";
export var coverTextContainer = "page-cover-module--coverTextContainer--sc0QH";