import React from "react";
import * as styles from "./page-cover.module.scss";
import keolis from "../../../assets/images/keolis.png";
import {displayImage} from "../../../global/functions/functions";
import keolisLogo from '../../../assets/images/logoforheader.png';


type RenderProps = {
    title: any,
    image: any
}

const PageCover:React.FC<RenderProps> = ({title, image}) => {

    return (
        <section className={styles.container}>
            <div className={styles.imageContainer}>
                {displayImage(image, styles.coverImage, "cover")}
            </div>
            <a aria-label={"retour a la page d'acceuil"} href={`/`}>
                <img src={keolisLogo} className={styles.logoContent} alt=""/>
            </a>
            <div className={styles.titleContent}>
                <h2 className={styles.title}>
                    {title}
                </h2>
                </div>
            {/* <div className={styles.titleContainer}>
                <img className={styles.backgroundImage} src={keolis} alt=""/>
                <div className={styles.titleContent}>
                    <h2 className={styles.title}>
                        {title}
                    </h2>
                </div>
            </div> */}
        </section>
    )

}

export default PageCover;